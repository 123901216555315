<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="customerPlanGrid"
          ref="customerPlanGridRef"
          :data-source="dataSource"
          :height="setHeight"
          :cache-enabled="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
        >
          <!--region    ::DataGird base components -->
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :filtering="false" :summary="false" />
            <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
            <dx-filter-row :visible="false" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
          <!--endregion ::DataGird base components -->
          <!--region    ::DataGird columns -->
          <dx-column data-field="planCategory" cell-template="planCategoryCellTemplate" />
          <template #planCategoryCellTemplate="{ data }">
            <span class="text-uppercase">{{ data.value }}</span>
          </template>
          <dx-column data-field="planType" />
          <dx-column data-field="planName" />
          <dx-column data-field="itemFee" cell-template="itemFeeCellTemplate" alignment="left" />
          <template #itemFeeCellTemplate="{ data }">
              {{ getCurrencyFormat(data) }}
          </template>
          <dx-column data-field="min" alignment="left" />
          <dx-column data-field="max" alignment="left" />
          <dx-column data-field="discount" :customize-text="percentDiscountText" alignment="left" />
          <dx-column data-field="status" :customize-text="normalizeText" alignment="center" cell-template="statusCellTemplate" />
          <template #statusCellTemplate="{ data }">
            <span class="badge" :class="`badge-${statusStyle(data.value)}`">{{ data.value | capitalize }}</span>
          </template>
          <dx-column data-field="warehouse" />
          <dx-column data-field="enrollmentDate" caption="Enroll Date" data-type="date" />
          <dx-column data-field="startDate" data-type="date" />
          <!--endregion ::DataGrid columns -->
          <!--region    ::DataGrid actions -->
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="80" :allow-resizing="false" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-box-arrow-right"
                hint="Unsubscribe"
                @click="onClickUnenrollPlan(data)"
              />
            </div>
          </template>
          <!--endregion ::DataGrid actions -->
          <!--region    ::DataGrid toolbar -->
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div class="mr-1">
                <dx-util-select-box
                  id="warehouseSelectBox"
                  v-model="selectedWarehouse"
                  :data-source="warehouseOptions"
                  display-expr="name"
                  value-expr="id"
                  @value-changed="searchByFilter"
                />
              </div>
              <div>
                <dx-util-select-box
                  id="planTypeSelectBox"
                  v-model="selectedPlanType"
                  :data-source="planTypeOptions"
                  display-expr="name"
                  value-expr="id"
                  @value-changed="searchByFilter"
                />
            </div>
          </div>
            </template>
          <!--endregion    ::DataGrid toolbar -->
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import planManagementService from '@/http/requests/plan/planManagementService'
import shipService from '@/http/requests/ship/shipService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { currencyFormatter } from '@core/utils/filter'
import { customerPlanDataSource } from './customerPlanStore'

export default {
  mixins: [GridBase],
  data() {
    return {
      dataSource: customerPlanDataSource,
      filters: {
        warehouseId: '',
        planType: '',
      },
      warehouseOptions: [],
      selectedWarehouse: 0,
      planTypeOptions: [],
      selectedPlanType: 0,
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.customerPlanGridRef.instance
      return grid
    },
  },
  mounted() {
    this.getWarehouseOptions()
    this.getPlanTypeOptions()
    this.initialLoad()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Plan Enrollment',
          icon: 'check',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => {
            this.$router.push({ name: 'route-account-plans-plan-enrollment' })
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.selectedWarehouse = 0
            this.selectedPlanType = 0
            this.initialLoad()
          },
        },
        location: 'after',
      })
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data.value)
    },
    normalizeText(cellInfo) {
      return this.$options.filters.capitalize(cellInfo.valueText)
    },
    percentDiscountText(cellInfo) {
      return `${cellInfo.valueText}%`
    },
    statusStyle(status) {
      if (status === 'active') return 'success'
      if (status === 'passive') return 'secondary'
      if (status === 'pending') return 'warning'
      if (status === 'rejected') return 'danger'
      return 'primary'
    },
    initialLoad() {
      const filters = {
        warehouseId: '',
        planType: '',
      }
      Object.assign(this.filters, filters)
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    searchByFilter(e) {
      if (e && e.element.id === 'warehouseSelectBox') this.filters.warehouseId = e.value
      if (e && e.element.id === 'planTypeSelectBox') this.filters.planType = e.value
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(this.filters)
      dataSource.load()
    },
    getWarehouseOptions() {
      this.warehouseOptions.splice(0, this.warehouseOptions.length)
      this.warehouseOptions.push({ id: 0, name: 'Location (All)' })
      shipService.fetchWarehouses().then(result => {
        result.data.forEach(element => {
          this.warehouseOptions.push({ id: element.id, name: element.name })
        })
      })
    },
    getPlanTypeOptions() {
      this.planTypeOptions.splice(0, this.planTypeOptions.length)
      this.planTypeOptions.push({ id: 0, name: 'Plan Type (All)' })
      planManagementService.fetchAllPlanTypes().then(result => {
        result.data.forEach(element => {
          this.planTypeOptions.push({ id: element.id, name: element.typeName })
        })
      })
    },
    onClickUnenrollPlan(e) {
      this.$swal({
        title: 'Are you sure you want to leave this plan?',
        text: "You won't be able to revert this! You need to re-enroll if you change your mind.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          planManagementService.leavePlan(e.row.data.id).then(() => {
            this.selectedWarehouse = 0
            this.selectedPlanType = 0
            this.initialLoad()
            this.$swal({
              icon: 'success',
              title: 'Plan Change!',
              text: 'You have been removed this plan.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
