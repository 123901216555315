import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import planManagementService from '@/http/requests/plan/planManagementService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    const pagebleString = pager.toCreatePageable()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    return planManagementService
      .fetchAllMyUserPlansByQuery(filters, pagebleString)
      .then(response => {
        const data = response.data
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
})

const customerPlanDataSource = new DataSource({
  store: store,
})

export {
  customerPlanDataSource,
}
